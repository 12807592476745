<template>
    <nav class="nav">
        <div class="nav-logo">
            <img
                height="200"
                width="200" 
                src="../../../../../public/assets/images/website/website-svgs/rehab-alhabeb/rehab-white.svg" 
                alt="logo"
            >
        </div>
        <div class="nav-title">
            <router-link :to="goTo">
                إحصائات وأرقام
            </router-link>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavComponent',
    data: () => ({
        goTo: ''
    }),
    created () {
        this.goTo = this.$route.meta.from == 'atroja' ? '/praies-atroja-details' : '/praies-details'
    },
}
</script>

<style lang="scss" scoped>
@import "../../../../../public/assets/styles/variables";
@import "../../../../../public/assets/styles/mixins";
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 5px 0 50px 0;
    @include respond(phone) {
        flex-direction: column;
        justify-content: center;
    }

    &-title {
        a {
            cursor: pointer;
            color: $white;
            font-size: 22px;
            font-weight: 700;
        }
    }
    &-logo,
    &-title {
        z-index: 999999;
    }
}
</style>