<template>
    <div class="row justify-content-center justify-content-lg-between align-items-center">
        <div class="col-12 col-lg-5 text-center text-lg-left section-hero__title mb-5 mb-lg-0">
            <h1>
                ”اللهم صلّ على سيدنا محمد النبيّ الأميّ
                الحبيب المحبوب عالي القدر عظيم الجاه
                و على آله و صحبه و سلم“
            </h1>
        </div>
        <div class="col-12 col-lg-4">
            <Form />
        </div>
    </div>
</template>

<script>
import Form from './form.vue';
export default {
    name: "SectionHeroComponent",
    components: { Form }
}
</script>

<style lang="scss" scoped>
@import "../../../../../public/assets/styles/variables";
@import "../../../../../public/assets/styles/mixins";

.section-hero {
    &__title {
        h1 {
            color: $white !important;
            font-size: 30px;
            font-weight: 700;
            line-height: 2;
            word-spacing: 5px;

            @include respond(phone) {
                font-size: 15px;
                padding: 0 20px;
            }
        }
    }
}
</style>