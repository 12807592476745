<template>
    <main>
        <v-pannellum 
            :showFullscreen="true"
            :mouseZoom="false"
            :doubleClickZoom="true"
            :orientation="true"
            :hfov="100"
            :yaw="-90"
            :pitch="0"
            :autoRotate="true"
            :src="equirectangularUrl" 
            :style="style">
        </v-pannellum>
        <SectionLayout style="z-index: 1;">
            <Nav />
            <SectionHero />
        </SectionLayout>
        <Footer />
    </main>
</template>

<script>
import SectionLayout from '../../website-components/website-rehab-alhabeb/section-layout.vue';
import Nav from '../../website-components/website-rehab-alhabeb/nav.vue';
import SectionHero from '../../website-components/website-rehab-alhabeb/section-hero.vue';
import Footer from '../../website-components/website-rehab-alhabeb/footer.vue';

export default {
    name: "RehabAlhabebPage",
    components: { SectionLayout, Nav, SectionHero, Footer },
    data: () => ({
        style: 'position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 0;',
        equirectangularUrl: require('../../../../../public/assets/images/website/website-rehab-alhabeb/background/black-bg.jpg')
    }),
    beforeRouteEnter () {
        window.location.replace("https://rihab-alhabib.islamic-apps.com");
    },
    beforeCreate () {
        window.location.replace("https://rihab-alhabib.islamic-apps.com");
    },
}
</script>

<style scoped>
main { min-height: 100vh; }
</style>