<template>
    <div class="form">
        <p class="form-title">
            أدخل العدد الكلي لصلواتك اليوم
        </p>
        <p class="form-subtitle">
            نرجو الانتباه ان يكون العدد الكلي لصلواتك كلها
        </p>
        <input 
            @keyup.enter="onSubmit(numberOfPraies, env)"
            v-model="numberOfPraies"
            placeholder="أدخل الرقم هنا ..."
            class="input-outline mb-5" 
            type="number"
        >
        <button
            @click="onSubmit(numberOfPraies, env)"
            :disabled="numberOfPraies < 1"
            class="button button-dark-green">
            إدخال
        </button>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'FormComponent',
    data: () => ({
        numberOfPraies: null,
        env: ''
    }),
    methods: {
        ...mapActions('userStore', ['sendPraies']),
        onSubmit(number, env) {
            this.sendPraies({ numberOfPraies: number, env })
                .then((res) => {
                    this.numberOfPraies = null
                })
        }
    },
    created () {
        this.env = this.$route.meta.from == 'atroja' ? 'dev' : 'prod'
    }
}
</script>

<style lang="scss" scoped>
@import "../../../../../public/assets/styles/variables";
@import "../../../../../public/assets/styles/components";
@import "../../../../../public/assets/styles/mixins";

.form {
    padding: 50px 34px;
    border-radius: 40px;
    background: $background-linear;
    backdrop-filter: $backdrop-filter;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-title {
        font-size: 22px;
        font-weight: 700;
        padding-bottom: 10px;
        @include respond(phone) {
            font-size: 19px;
            padding-bottom: 5px;
        }
    }

    &-subtitle {
        font-size: 14px;
        font-weight: 700;
        padding-bottom: 50px;
        word-spacing: 2px;
        @include respond(phone) {
            font-size: 12px;
        }
    }
    
    &-title,
    &-subtitle {
        word-spacing: 3px;
        display: inline;
        white-space: nowrap;
        color: $white;
        // width: 100%;
    }

}
</style>