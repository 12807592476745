<template>
    <footer class="footer">
        <div class="footer__total">
            <p class="footer__total-number">
                {{ getParticipants }}
            </p>
            <p class="footer__total-title">
                العدد الكلي للمشاركين
            </p>
        </div>
        <div class="footer__total mb-4 mb-lg-0">
            <p class="footer__total-number">
                {{ getUserScore }}
            </p>
            <p class="footer__total-title">
                عدد صلواتك
            </p>
        </div>
        <div class="footer__total">
            <p class="footer__total-number">
                {{ getPrayers }}
            </p>
            <p class="footer__total-title">
                العدد الكلي للصلوات
            </p>
            <p class="footer__total-subtitle">
                على النبي صلى الله عليه وسلم
            </p>
        </div>
    </footer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'FooterComponent',
    computed: {
        ...mapGetters('userStore', [
            'getUserLoggedin',
            'getAnalyitcsData',
            'getKeys'
        ]),
        getUserScore() {
            return this.getUserLoggedin.score ? this.getUserLoggedin.score.toLocaleString('en-US') : 0
        },
        getParticipants() {
            return this.getAnalyitcsData ? (
                parseInt(this.getAnalyitcsData.rehab_al_habeb_visitors_1444) +
                parseInt(this.getAnalyitcsData.rehab_al_habeb_visitors_1443) +
                parseInt(this.getAnalyitcsData.rehab_al_habeb_visitors)
            ).toLocaleString('en-US') : 0
        },
        getPrayers() {
            return this.getAnalyitcsData ? (
                parseInt(this.getAnalyitcsData.rehab_al_habeb_prayers_1444) +
                parseInt(this.getAnalyitcsData.rehab_al_habeb_prayers_1443) +
                parseInt(this.getAnalyitcsData.rehab_al_habeb_prayers)
            ).toLocaleString('en-US') : 0
        }
    },
    methods: {
        ...mapActions('userStore', ['getAnalyitcs']),
    },
    created() {
        const env = this.$route.meta.from == 'atroja' ? 'dev' : 'prod'
        this.getAnalyitcs({ keys: this.getKeys, env });
    }
}
</script>

<style lang="scss" scoped>
@import "../../../../../public/assets/styles/variables";
@import "../../../../../public/assets/styles/mixins";

.footer {
    width: 80%;
    margin: 100px auto 0 auto;
    padding: 30px 15px 18px 15px;
    border-radius: 50px 50px 0px 0px;
    background: linear-gradient(90deg, #000000 -52.36%, rgba(0, 0, 0, 0.1) 98.21%);
    backdrop-filter: blur(50px);
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;

    @include respond(tab-port) {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    &__total {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-number {
            color: $white;
            font-size: 38px;
            font-weight: 700;
            padding-bottom: 15px;
        }

        &-title {
            font-size: 18px;
        }

        &-subtitle {
            font-size: 12px;
        }

        &-title,
        &-subtitle {
            color: $brown;
            font-weight: 700;
        }
    }
}
</style>